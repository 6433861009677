import getImage from "../functions/image";

function About({ about, images }) {
	return (
		<section className="home__about w-full jcc-aic">
			<div className="home__about-container">
				<h2 className="home__heading">{about.title}</h2>

				{about.points.map((point, index) => {
					return <AboutBlock point={point} key={index} />;
				})}
			</div>
		</section>
	);

	function AboutBlock({ point }) {
		return (
			<div className={"home__about-block jcsb-aic"}>
				<div className="home__about-block--image jcsb-aic">
					<img src={getImage(images, point)} alt={point.title} />
				</div>
				<div className="home__about-block--info jcsb-aic">
					<div className="home__about-block--info-div">
						<h3 className="home__about-block--title">
							{point.title}
						</h3>

						<p className="home__about-block--text">
							{point.information}
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default About;
