import Slider from "../components/slider";
import device from "../functions/device";

function Reviews({ reviews }) {
	const sliderConfig = {
		arrows: true,
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		dots: false,
		speed: 1000,
	};
	const sliderConfigMob = { ...sliderConfig };

	sliderConfigMob.slidesToShow = 1;

	return (
		<section className="home__reviews w-full jcc-aic">
			<div className="home__reviews-container jcc-aic wrap">
				<h2 className="home__heading w-full">{reviews.title}</h2>

				<div className="home__reviews-block jcc-aic">
					<Slider
						config={
							device("desktop") ? sliderConfig : sliderConfigMob
						}
						arrows={true}
					>
						{reviews.reviews.map((slide, index) => {
							return <Slide slide={slide} key={index} />;
						})}
					</Slider>
				</div>
			</div>
		</section>
	);

	function Slide({ slide }) {
		return (
			<div className="home__reviews-slide jcfs-aifs relative wrap">
				<div className="home__reviews-title jcsb-aic">
					<img src="./media/quote.svg" alt="quote" />

					<h3 className="home__reviews-title">{slide.client}</h3>
				</div>

				<p className="home__reviews-text">{slide.review}</p>
			</div>
		);
	}
}

export default Reviews;
