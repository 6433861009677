import { useEffect } from "react";

function Developer() {
	useEffect(() => {
		window.addEventListener("message", (event) => {
			let parent = document.getElementById("developer");
			let height = event.data.height;

			parent.style.height = height;
			parent.style.overflow = "hidden";
		});
	}, []);

	return (
		<section
			id="developer"
			className="jcc-aic w-full"
			style={{ padding: 0, margin: 0, height: 0 }}
		>
			<iframe
				src="https://www.itbevz.ru/dev"
				frameBorder="0"
				title="itbevz.ru"
			></iframe>
		</section>
	);
}

export default Developer;
