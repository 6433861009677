import Slider from "../components/slider";
import device from "../functions/device";
import getImage from "../functions/image";

function Partners({ partners, images }) {
	const sliderConfig = {
		arrows: true,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		speed: 1000,
	};
	const sliderConfigMob = { ...sliderConfig };

	sliderConfigMob.slidesToShow = 1;

	return (
		<section className="home__partners w-full jcc-aic">
			<div className="home__partners-container jcc-aic wrap">
				<h2 className="home__heading w-full">{partners.title}</h2>

				<div className="home__partners-slider jcc-aic">
					<Slider
						config={
							device("desktop") ? sliderConfig : sliderConfigMob
						}
						arrows={true}
					>
						{partners.list_partners.map((slide, index) => {
							return <Slide slide={slide} key={index} />;
						})}
					</Slider>
				</div>
			</div>
		</section>
	);

	function Slide({ slide }) {
		return (
			<div className="home__partners-slide jcc-aic relative">
				<img src={getImage(images, slide)} alt={slide.name_partner} />
			</div>
		);
	}
}

export default Partners;
