import device from "../functions/device";

import getImage from "../functions/image";

function Header({ contacts, images }) {
	const header = contacts;

	return (
		<header className="header jcc-aic w-full">
			<div className="header__container jcsb-aic">

				<div className="header__logo">
					<img src={getImage(images, header)} alt="logo" />
				</div>

				<div className="header__vk">
					<a href="https://vk.com/reutovprotez">
						<img src="./media/vk_blue.svg" alt="vk" />
					</a>
				</div>


				{device("mobile") && (
					<div className="header__mail jcsb-aic">
						<a href={`mailto:${header.email}`}>
							<img src="./media/mail.svg" alt="mail" />
						</a>
						<span>{header.email}</span>
					</div>
				)}

				<div className="header__phone jcsb-aic wrap">
					<img src="./media/phone.svg" alt="phone" />

					<div className="header__phone-phones">
						<div className="header__phone-item">
							<a href={`tel:${header.main_phone_number}`}>
								{header.main_phone_number}
							</a>

							<br />

							<a href={`tel:${header.further_phone_number}`}>
								{header.further_phone_number}
							</a>
						</div>
					</div>
				</div>

				{!device("mobile") && (
					<div className="header__mail jcsb-aic">
						<img src="./media/mail.svg" alt="mail" />

						{header.email}
					</div>
				)}

				{!device("mobile") && (
					<div className="header__address jcsb-aic">
						<img src="./media/map.svg" alt="map" />

						{header.address}
					</div>
				)}
			</div>
		</header>
	);
}

export default Header;
