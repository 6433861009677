import Button from "../components/button";
import getImage from "../functions/image";

function Products({ popupCheck, products, images }) {
	return (
		<section className="home__products w-full jcc-aic">
			<div className="home__products-container">
				<h2 className="home__heading">{products.title}</h2>

				{products.products.map((product, index) => {
					return <Product product={product} key={index} />;
				})}
			</div>
		</section>
	);

	function Product({ product }) {
		return (
			<div className="home__products-product jcsa-aic">
				<div className="home__products-product--info">
					<h3 className="home__products-product--title">
						{product.name}
					</h3>

					<p className="home__products-product--text">
						{product.description}
					</p>

					<Button
						info={product}
						className="home__products-product--button"
						setter={popupCheck}
					/>
				</div>

				<div className="home__products-product--image jcc-aic">
					<img src={getImage(images, product)} alt={product.name} />
				</div>
			</div>
		);
	}
}

export default Products;
