import { Switch, Route } from "react-router-dom";

import Home from "./js/pages/home";
import NotFound from "./js/pages/404";

function App() {
	return (
		<Switch>
			<Route exact path="/">
				<Home />
			</Route>
			<Route>
				<NotFound />
			</Route>
		</Switch>
	);
}

export default App;
