function Map() {
	return (
		<div className="home__contacts-map">
			<div
				className="home__contacts-map--block"
				style={{ position: "relative", overflow: "hidden" }}
			>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2244.2404938249674!2d37.84607107815792!3d55.771694880854945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414acb04b624e551%3A0xd884b3ee6328a52b!2z0YPQuy4g0KDQtdGD0YLQvtCy0YHQutC40YUg0L7Qv9C-0LvRh9C10L3RhtC10LIsIDQsINCg0LXRg9GC0L7Qsiwg0JzQvtGB0LrQvtCy0YHQutCw0Y8g0L7QsdC7LiwgMTQzOTY4!5e0!3m2!1sru!2sru!4v1627394135111!5m2!1sru!2sru"
					width="560"
					height="400"
					style={{ position: "relative" }}
					title="map"
				></iframe>
			</div>
		</div>
	);
}

export default Map;
