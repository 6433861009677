import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import device from "../functions/device";

function Slider({ config, children, arrows = false }) {
	if (arrows) {
		config = { ...config, nextArrow: <Arrow />, prevArrow: <Arrow /> };
	}

	return <Slick {...config}>{children}</Slick>;
}

function Arrow(props) {
	const { className, onClick } = props;

	return (
		<div className={className} onClick={onClick}>
			<img
				src={
					device("desktop")
						? "./media/arrow-double.svg"
						: "./media/arrow-single.svg"
				}
				alt="arrow"
			/>
		</div>
	);
}

export default Slider;
