import Button from "../components/button";
import device from "../functions/device";
import getImage from "../functions/image";

function Services({ popupCheck, services, images }) {
	return (
		<section className="home__services w-full jcc-aic">
			<div className="home__services-container">
				<h2 className="home__heading">{services.title}</h2>
				<div className="home__services-block">
					{services.services.map((service, index) => {
						return (
							<Service
								service={service}
								index={index}
								key={index}
							/>
						);
					})}
				</div>
			</div>
		</section>
	);

	function Service({ service, index }) {
		return (
			<div
				className={`home__services-service jcsb-aic wrap ${
					device("desktop")
						? index % 2 !== 0
							? "home__services-service--reversed"
							: ""
						: "home__services-service--reversed"
				}`}
			>
				<div className="home__services-service--info">
					<h3 className="home__services-service--title">
						{service.name}
					</h3>

					<p className="home__services-service--text">
						{service.description}
					</p>

					<Button
						info={service}
						className="home__services-service--button"
						setter={popupCheck}
					/>
				</div>
				<div className="home__services-service--image">
					<img src={getImage(images, service)} alt={services.name} />
				</div>
			</div>
		);
	}
}

export default Services;
