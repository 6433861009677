import React from "react";
import ReactDOM from "react-dom";
import App from "./router";

import { BrowserRouter } from "react-router-dom";

import "./scss/stylesheet.scss";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
