import Button from "../components/button";
import device from "../functions/device";

function Helper({ popupCheck, docs }) {
	return (
		<section className="home__helper w-full jcc-aic">
			<div className="home__helper-container jcsb-aic">
				<div className="home__helper-image">
					<img src="./media/doc.svg" alt="doc" />

					{device("mobile") && (
						<Button
							info={docs}
							className="home__helper-button"
							setter={popupCheck}
						/>
					)}
				</div>

				<div className="home__helper-info">
					<h2 className="home__helper-info--title">{docs.title}</h2>

					<p className="home__helper-info--text">
						{docs.description}
					</p>

					{device("desktop") && (
						<Button
							info={docs}
							className="home__helper-button"
							setter={popupCheck}
						/>
					)}
				</div>
			</div>
		</section>
	);
}

export default Helper;
