const source = "";

function getImage(images, info) {
	let link = source;
	let index = 0;

	if (info.background) {
		index = info.background;
	}

	if (info.image) {
		index = info.image;
	}

	if (info.logo_org) {
		index = info.logo_org;
	}

	images.forEach((image) => {
		if (image.id === index) {
			link += image.meta.download_url;
		}
	});

	return link;
}

export default getImage;
