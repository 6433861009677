import Map from "../components/map";
import getImage from "../functions/image";

function Contacts({ contacts, images }) {
	return (
		<section className="home__contacts w-full jcc-aic">
			<div className="home__contacts-container">
				<h2 className="home__heading w-full">{contacts.title}</h2>

				<div className="home__contacts-block jcsb-aic wrap">
					<div className="home__contacts-data">
						<div className="home__contacts-logo jcfs-aic">
							<img src={getImage(images, contacts)} alt="logo" />

							<p className="home__contacts-title">
								{contacts.name_org}
							</p>
						</div>

						<div className="home__contacts-info">
							<div className="home__contacts-info--phone jcfs-aic">
								<img src="./media/phone.svg" alt="phone" />

								<p className="home__contacts-phone">
									<a
										href={`tel:${contacts.main_phone_number}`}
									>
										{contacts.main_phone_number}
									</a>

									<br />

									<a
										href={`tel:${contacts.further_phone_number}`}
									>
										{contacts.further_phone_number}
									</a>
								</p>
							</div>
							<div className="home__contacts-info--mail jcfs-aic">
								<img src="./media/mail.svg" alt="mail" />

								<p className="home__contacts-mail">
									{contacts.email}
								</p>
							</div>
							<div className="home__contacts-info--address jcfs-aic">
								<img src="./media/map.svg" alt="map" />

								<p className="home__contacts-address">
									{contacts.address}
								</p>
							</div>
							<div className="home__contacts-info--hours jcfs-aic">
								<img src="./media/clock.svg" alt="clock" />

								<p className="home__contacts-hours">
									{contacts.work_mode}
								</p>
							</div>

							<div className="home__contacts-info--vk jcfs-aic">
								<a href="https://vk.com/reutovprotez">
									<img src="./media/vk_white.svg" alt="vk" />
								</a>
							</div>
						</div>
					</div>

					<Map />
				</div>
			</div>
		</section>
	);
}

export default Contacts;
