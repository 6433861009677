import React from "react";

import info from "../../info/home.json";

function Popup({ setter, contacts }) {
	return (
		<div className="popup fixed wh-full jcc-aic">
			<div className="popup__block jcc-aic">
				<div className="popup__content">
					<div className="popup__content-top jcsb-aic">
						<p className="popup__title">{info.popup.title}</p>

						<div className="popup__close" onClick={setter}>
							<img src="./media/close.svg" alt="close" />
						</div>
					</div>
					<div className="popup__content-bottom jcsb-aife">
						<div className="popup__info">
							<p>{info.popup.phone}</p>

							<h2>
								<a href={`tel:${contacts.main_phone_number}`}>
									{contacts.main_phone_number}
								</a>

								<br />

								<a
									href={`tel:${contacts.further_phone_number}`}
								>
									{contacts.further_phone_number}
								</a>
							</h2>

							<p>{info.popup.email}</p>

							<h2>
								<a href={`mailto:${contacts.email}`}>
									{contacts.email}
								</a>
							</h2>
						</div>

						{/* <div className="popup__logo">
							<img src="./media/logo.png" alt="logo" />
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(Popup);
