import Slider from "../components/slider";
import Button from "../components/button";
import getImage from "../functions/image";

function Banner({ popupCheck, slider, images }) {
	const bannerConfig = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 5000,
		cssEase: "ease-out",
	};

	return (
		<section className="home__banner relative w-full">
			<Slider config={bannerConfig}>
				{slider.slides.map((slide, index) => {
					return <Slide slide={slide} key={index} />;
				})}
			</Slider>

			{/* <div className="home__banner-logo absolute">
				<img src="./media/logo.png" alt="logo" />
			</div> */}
		</section>
	);

	function Slide({ slide }) {
		return (
			<div className="home__banner-slide jcc-aic relative">
				<div className="home__banner-container jcfs-aic">
					<div className="home__banner-block jcfs-aifs wrap">
						<h1 className="home__banner-title">{slide.title}</h1>

						<p className="home__banner-text">{slide.description}</p>

						<Button
							info={slide}
							className="home__banner-button"
							setter={popupCheck}
						/>
					</div>
				</div>
				<img
					className="absolute"
					src={getImage(images, slide)}
					alt={slide.title}
				/>
			</div>
		);
	}
}

export default Banner;
