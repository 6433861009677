import axios from "axios";

async function getData(endpoint) {
	const api = "/api/v2/";
	const query = axios.get(`${api}${endpoint}`); // вынести api в appConfig
	const { data } = await query;

	query.catch((error) => {
		throw new Error(error);
	});

	return new Promise((resolve) => resolve(data));
}

export { getData };
