import { useCallback, useEffect, useState } from "react";
import { getData } from "../functions/data";

import Loader from "../components/preloader";
import Header from "../components/header";
import Popup from "../components/popup";

import overflow from "../functions/overflow";

import Banner from "../blocks/banner";
import Products from "../blocks/products";
import Services from "../blocks/services";
import About from "../blocks/about";
import Partners from "../blocks/partners";
import Helper from "../blocks/helper";
import Reviews from "../blocks/reviews";
import Contacts from "../blocks/contacts";
import Developer from "../blocks/developer";

const homeLink = "pages/4/";
const imagesLink = "images/?limit=100000";

let slider = null;
let products = null;
let services = null;
let about = null;
let partners = null;
let docs = null;
let reviews = null;
let contacts = null;
let images = null;

function Home() {
	const [data, setData] = useState(null);
	const [dataImages, setDataImages] = useState(null);

	useEffect(() => {
		getData(homeLink).then((data) => {
			data.content.forEach((item) => {
				let value = item.value;

				if (item.type === "title_slider") slider = value;
				if (item.type === "our_products") products = value;
				if (item.type === "our_services") services = value;
				if (item.type === "about_us") about = value;
				if (item.type === "our_partners") partners = value;
				if (item.type === "docs_helper") docs = value;
				if (item.type === "reviews_clients") reviews = value;
				if (item.type === "contacts") contacts = value;
			});

			setData(data);
		});

		getData(imagesLink).then((data) => {
			images = data.items;

			setDataImages(data);
		});
	}, []);

	return (
		<div className="home jcc-aifs wrap">
			{!(contacts && data && dataImages) ? (
				<Loader />
			) : (
				<>
					<Header contacts={contacts} images={images}/>

					<Main />
				</>
			)}
		</div>
	);
}

function Main() {
	const usePopup = (initialState = false) => {
		const [state, setState] = useState(initialState);

		const toggle = useCallback(() => {
			let current = "auto";

			if (!state) {
				current = "hidden";
			}

			overflow(current);

			setState((state) => !state);
		}, [state]);

		return [state, toggle];
	};

	const [popupState, popupCheck] = usePopup();

	return (
		<div className="home__main jcc-aifs wrap w-full">
			{slider && (
				<Banner
					popupCheck={popupCheck}
					slider={slider}
					images={images}
				/>
			)}
			{products && (
				<Products
					popupCheck={popupCheck}
					products={products}
					images={images}
				/>
			)}
			{services && (
				<Services
					popupCheck={popupCheck}
					services={services}
					images={images}
				/>
			)}
			{about && <About about={about} images={images} />}
			{partners && <Partners partners={partners} images={images} />}
			{docs && <Helper popupCheck={popupCheck} docs={docs} />}
			{reviews && <Reviews reviews={reviews} />}
			{contacts && <Contacts contacts={contacts} images={images} />}

			<Developer />

			{popupState && <Popup setter={popupCheck} contacts={contacts} />}
		</div>
	);
}

export default Home;
